:root {
	--bs-primary-rgb: 35, 17, 96;
}

body {
	font-family: "Muli", sans-serif;
	background-color: #000;
	background-image: url('./assets/images/background.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	color: #fff;
}

img {
	max-width: 100%;
}

.navbar {
	background: #1b192e;
	background-image: url('./assets/images/background.jpg');
	background-repeat: no-repeat;
	background-size: cover;
	padding: 10px 0;
}

.navbar .nav-link {
	color: #fff;
  font-weight: 600;
}

.navbar-collapse ul.navbar-nav {
	display: flex;
	align-items: center;
	list-style: none;
	margin: 0;
	padding: 0;
}

.navbar-collapse ul.navbar-nav .nav-link {
	text-decoration: none;
	padding: 15px;
	display: block;
	color: #81808c;
	font-weight: 600;
}

.navbar-collapse ul.navbar-nav .nav-link.active {
	color: #fff
}

@media (min-width: 992px) {
	.navbar-toggler {
		display: none
	}
}

.h1,
h1 {
	font-size: 4.688em;
}

.h2,
h2 {
	font-size: 2.6em;
}

.h3,
h3 {
	font-size: 2em;
}

.h4,
h4 {
	font-size: 1.58em;
}

.h5,
h5 {
	font-size: 1.375em;
}

.h6,
h6 {
	font-size: 1em;
}

p {
	font-size: 1.25em;
	color: rgba(255, 255, 255, 0.6);
}

p a {
	color: #0071BC;
	text-decoration: none;
}

p a:hover,
p a:focus,
p a:active {
	color: rgba(255, 255, 255, 0.8);
}

.fs-20 {
	font-size: 1.25em;
}

.fs-25 {
	font-size: 1.563em;
}

.fs-30 {
	font-size: 1.875em;
}

section,
footer {
	position: relative;
	overflow: hidden
}

.container {
	position: relative;
	z-index: 1;
}

.bg-gray {
	background: #f1ebf9
}

.rounded-xl {
	border-radius: 25px;
}

.btn {
	display: inline-block;
	font-weight: 400;
	line-height: 1.5;
	color: #212529;
	text-align: center;
	text-decoration: none;
	vertical-align: middle;
	cursor: pointer;
	-webkit-user-select: none;
	-moz-user-select: none;
	user-select: none;
	background-color: transparent;
	border: 1px solid transparent;
	padding: 0.375em 0.75em;
	font-size: 1em;
	transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
	border-radius: 9px !important;
}

.btn-primary {
	color: #e3d8f3;
	background-color: #0071BC;
	border-color: #0071BC;
	text-transform: capitalize;
	font-weight: bold;
	box-shadow: 0px 10px 16px #0000007A;
}

.btn-primary:hover {
	background-color: transparent;
	color: #0071BC;
}

.btn-lg {
	font-size: 1.125em;
	border-radius: 0.3em;
	font-weight: 600;
	height: 70px;
	min-width: 215px;
	display: inline-flex;
	align-items: center;
	justify-content: center
}

.fw-600 {
	font-weight: 700
}

.contact-form {
	background: #fff;
	border-radius: 52px;
	box-shadow: 0px 14px 99px #7B5AA73D;
}

.form-control {
	min-height: 65px;
	background: #1E2125;
	border: 0;
	border-radius: 24px;
	width: 100%;
	padding: 0 30px;
	font-size: 14px;
	resize: none;
	outline: none;
	color: #fff;
}

.form-control::placeholder {
	font: normal normal 500 20px/65px;
	letter-spacing: 0px;
	color: rgba(255, 255, 255, 0.5);
}

footer {
	background: #121212
}

.copyright {
	background: #090909;
	color: rgba(255, 255, 255, .5);
	font-size: 16px
}

.footer-links {
	border-top: 1px solid #323043
}

.inline-items {
	display: flex;
	align-items: center;
	justify-content: center;
}

.inline-items .link {
	text-decoration: none;
	font-size: 18px;
	color: #fff;
	margin: 0 30px;
  font-weight: 600;
}

.social-links {
	display: flex;
	align-items: center;
	justify-content: center
}

.social-links a {
	text-decoration: none;
	margin: 0 10px
}

.splide__list {
	height: auto !important
}

.splide__arow {
	width: 54px;
	height: 54px;
	border-radius: 50%;
	box-shadow: 0px 3px 34px #0000001C;
	background-color: #FFFFFF;
	border: 0;
}

.splide__arow svg path {
	fill: #4C3381;
}

.splide__arow:hover {
	background: linear-gradient(148deg, #7B5AA7 0%, #231160 100%);
}

.splide__arow:hover svg path {
	fill: #fff;
}

.splide__arrows {
	gap: 40px;
	display: inline-flex;
}

.banner p {
	font-size: 1.5em;
}

.text-default,
.text-default:hover {
	color: #7d56a5
}

.text-justify {
	text-align: justify;
}

@media (max-width: 767px) {
	.banner {
		background-image: none !important;
	}

	.h1,
	h1 {
		font-size: 2em;
	}

	.h2,
	h2 {
		font-size: 2em;
	}

	.h3,
	h3 {
		font-size: 1.5em;
	}

	.h4,
	h4 {
		font-size: 1em;
	}

	.h5,
	h5 {
		font-size: 1em;
	}

	.btn-lg {
		font-size: 1.2em;
		height: 68px;
	}

	img {
		max-width: 100%;
	}

	.contact-form {
		border-radius: 22px;
	}

	.inline-items {
		flex-wrap: wrap;
	}

	.inline-items .link {
		font-size: 16px;
		margin: 4px 14px;
		flex: 0 0 100%;
	}

	.navbar {
		padding: 10px 0
	}

	.navbar .container {
		flex-direction: column;
	}

	.navbar-collapse ul.navbar-nav {
		flex-wrap: wrap;
		justify-content: center;
	}

	.navbar-collapse ul.navbar-nav .nav-link {
		padding: 5px 10px;
		font-size: 14px;
	}
}

.container-xxxl {
	max-width: 1320px;
	width: 90%;
	--bs-gutter-x: 1.5em;
	--bs-gutter-y: 0;
	padding-right: calc(var(--bs-gutter-x)*.5);
	padding-left: calc(var(--bs-gutter-x)*.5);
	margin-right: auto;
	margin-left: auto;
}

.navbar .container-xxxl {
	display: flex;
	flex-wrap: inherit;
	align-items: center;
	justify-content: space-between;
}

@media (min-width: 576px) {
	.container-xxxl {
		max-width: 740px;
		width: 90%;
	}
}

@media (min-width: 768px) {
	.container-xxxl {
		max-width: 920px;
		width: 90%;
	}
}

@media (min-width: 992px) {
	.container-xxxl {
		max-width: 1060px;
		width: 90%;
	}
}

@media (min-width: 1200px) {
	.container-xxxl {
		max-width: 1340px;
		width: 90%;
	}

	body {
		font-size: 13px;
	}
}

@media (min-width: 1600px) {
	.container-xxxl {
		max-width: 1575px;
		--bs-gutter-x: 1.5em;
		--bs-gutter-y: 0;
		width: 100%;
		padding-right: calc(var(--bs-gutter-x)*.5);
		padding-left: calc(var(--bs-gutter-x)*.5);
		margin-right: auto;
		margin-left: auto;
	}

	body {
		font-size: 16px;
	}
}

@media (min-width: 768px) and (max-width: 1024px) {

	.h3,
	h3 {
		font-size: 1.8em;
	}

	.h4,
	h4 {
		font-size: 1.2em;
	}

	.h5,
	h5 {
		font-size: 1.2em;
	}

	.contact-form {
		border-radius: 30px
	}
}

.banner-right {
	background-position: right bottom;
	background-repeat: no-repeat;
	background-size: contain;
}

.banner {
	padding-top: 200px;
	padding-bottom: 150px;
}
@media (min-width:1440px){
	.banner {
		padding-top: 230px;
		padding-bottom: 180px;
	}
	.h1,
	h1 {
		font-size: 6em;
	}
}

.py-10 {
	padding-top: 6.25em;
	padding-bottom: 6.25em;
}

.fs-22 {
	font-size: 1.375em;
}

.fs-14 {
	font-size: 0.875em;
}

.fs-17 {
	font-size: 1.063em;
}

.fs-18 {
	font-size: 1.125em;
}

.social {
	border-radius: 50%;
	width: 37px;
	height: 37px;
	background: rgba(255, 255, 255, 0.1);
	display: inline-flex;
	justify-content: center;
	align-items: center;
	font-weight: bold;
}

.apps-logos img {
	height: 70px;
}

.logo img {
	height: 170px;
}

.team_img {
	height: 219px;
}

.footer_logo {
	height: 80px;
}

.boxwrapper {
	background-color: rgba(255, 255, 255, 0.24);
	padding: 25px;
	border-radius: 25px;
}

.custom-accordian .accordion-item {
	border: 1px solid rgba(255, 255, 255, 0.3);
	border-radius: 44px;
	background-color: transparent;
}

.custom-accordian .accordion-header,
.custom-accordian .accordion-button {
	border-radius: inherit !important;
}

.custom-accordian .accordion-button,
.custom-accordian .accordion-button:focus-within {
	background-color: transparent;
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
	outline: none;
	box-shadow: none;
	font-size: 1.25em;
}

.custom-accordian .accordion-body {
	font-size: 1em;
}

.custom-accordian .accordion-header {
	padding: 30px;
}

.custom-accordian .accordion-button {
	background-image: url('./assets/images/arrow-up.svg');
	background-position: 98% 50%;
	background-repeat: no-repeat;
}

.custom-accordian .accordion-header:has(.collapsed) .accordion-button {
	background-image: url('./assets/images/arrow-down.svg');
}

@media (max-width:1500px) {
	body {
		font-size: 13px;
	}

	.logo img {
		height: 130px;
	}

	.team_img {
		width: 115px;
		height: 115px;
	}

	.footer_logo {
		height: 80px;
	}

	.h1,
	h1 {
		font-size: 4em;
	}
  .banner-right{
    background-size: 50%;
  }
  .btn-lg {
    height: 50px;
    min-width: 170px;
  }
  .apps-logos img {
    height: 60px;
  }
  .h2, h2 {
    font-size: 2em;
  }
}

@media (max-width:1199px) {
	body {
		font-size: 10px;
	}

	.logo img {
		height: 100px;
	}

	.team_img {
		height: 140px;
		width: 140px;
	}

	.footer_logo {
		height: 80px;
	}

	.py-10 {
		padding-top: 5.25em;
		padding-bottom: 5.25em;
	}

	.btn-lg {
		font-size: 1.5em;
		height: 65px;
	}
}

@media (max-width: 992px) {

	.h1,
	h1 {
		font-size: 5.563em;
	}

	.banner {
		padding-top: 150px;
		padding-bottom: 120px;
	}

	.banner-right {
		background-size: 65%;
	}

	.apps-logos img {
		height: auto;
	}
}

@media (max-width: 767px) {
	.full-banner {
		background-size: cover;
	}

	.apps-logos {
		justify-content: center;
	}

	.custom-accordian .accordion-header {
		padding: 16px 30px;
	}

	.apps-logos img {
		height: auto;
	}

	.footer_logo {
		height: 45px;
	}

	.team_img {
		height: 80px;
		width: 80px;
	}

	.boxwrapper {
		padding: 10px 13px;
	}
}

@media (max-width: 575px) {

	.h1,
	h1 {
		font-size: 3.563em;
	}

	.logo img {
		height: 80px;
	}

	.inline-items .link {
		font-size: 14px;
	}

	.banner {
		padding-top: 100px;
		padding-bottom: 70px;
	}
}
#mybody{
	background-image: url("./assets/images/card-in-hand.png");
}
.list{
	font-size: large;

	margin-top: 2rem; 
	margin-bottom: 1rem;
}
