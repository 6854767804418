.accordion {
    border-radius: 5px;
   display: flex;
   flex-direction: column;
   row-gap: 2rem;
  }
  
  .title {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color:none;
    border-radius: 80px;
    border: 0.8px solid rgb(214, 210, 210);
    cursor: pointer;
    height: 60px;    
  }
  
  .content {
    margin-top: 10px;
    padding: 20px;
    display: none;
    color: white;
    border-radius: 30px;
  }
  
  .active {
    pointer-events: none;
    display:block;
    border-radius: 2px solid white;
   
  }
  
  /* Add any additional styling you need */
  